.page {
  font-size: 11px;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 425px;
}

.logo {
  width: 45px;
  height: 45px;
  background-color: brown;
}

.reportTitle {
  font-size: 12px;
  color: #808080;
}

.footer {
  font-size: 12px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  color: #808080;
  width: 425px;
}

.generalInfo {
  font-size: 8px;
  font-style: semibold;
  width: 425px;
}

.invoice {
  font-weight: bold;
  font-size: 20px;
}

.invoiceNumber {
  font-size: 11px;
  font-weight: bold;
}

.address {
  font-weight: 400;
  font-size: 10px;
}

.theader {
  margin-top: 20px;
  font-size: 10px;
  font-style: bold;
  padding-top: 4px;
  padding-left: 7px;
  flex: 1;
  height: 20px;
  background-color: #dedede;
  border-color: whitesmoke;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.theader2 {
  flex: 2;
  border-right-width: 0;
  border-bottom-width: 1px;
}

.tbody {
  font-size: 9px;
  padding-top: 4px;
  padding-left: 7px;
  flex: 1;
  border-color: whitesmoke;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.total {
  font-size: 9px;
  padding-top: 4px;
  padding-left: 7px;
  flex: 1.5;
  border-color: whitesmoke;
  border-bottom-width: 1px;
}

.tbody2 {
  flex: 2;
  border-right-width: 1px;
}
